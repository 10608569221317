import { Grid, Paper, Typography, LinearProgress, Button, Divider, Box } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

import BuildIcon from "@mui/icons-material/Build";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchTools } from "../../constants/apiURLs";

import DataGridTool from "./dataGridTool";

import { addToolAPI } from "../../constants/apiURLs";

export const ToolList = () => {
    const {
        status: isLoadingTools,
        error: errorTools,
        data: tools,
    } = useQuery({
        queryKey: ["get-tools"],
        queryFn: fetchTools,
        onSuccess: (data) => {
            console.log("Tools data fetched:", data); // Este log se asegura de mostrar los datos cuando llegan
          },
    });
    
    const queryClient = useQueryClient();
    const addToolMutation = useMutation(addToolAPI, {
        onSuccess: () => {
            queryClient.invalidateQueries(["get-tools"]);
            console.log("Herramienta añadida y lista actualizada.");
        },
        onError: (error) => {
            console.error("Error al añadir herramienta:", error);
        },
    });

    if (isLoadingTools !== "success")
        return <LinearProgress />
    
    return (
        <Grid container style={{ justifyContent: "center", alignItems: "flex-start"}}>
            <Grid item container xs={11} lg={11} style={{ marginTop: 30 }}>
                <Paper style={{ width: "100%", padding: 30, textAlign: "justify", opacity: 0.95 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                        <BuildIcon fontSize="large" style={{ verticalAlign: 'middle' }} />
                        <Typography variant="h4" ml={1}>
                            Herramientas
                        </Typography>
                    </Box>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end" direction="row">
                        <Typography variant="h6" ml={2} sx={{ lineHeight: 0.8 }}>
                            Lista de Herramientas
                        </Typography>
                        <Link to={"/modulo_km/add_tool/"} state={{ addToolMutation }}>
                            <Button
                                variant="contained"
                                style={{backgroundColor: "#014169", color: "white", minWidth: 150, minHeight: 50}}
                            >
                                Añadir Herramienta
                            </Button>
                        </Link>
                    </Grid>
                    <Divider variant="middle" color="gray" sx={{ mt: 3 }} />
                    <Grid id="table_grid" container justifyContent="center" alignItems="center" style={{marginTop: 20, height: 453}}>
                        <DataGridTool
                            rows={tools}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ToolList;
