import React, { useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { addToolAPI } from "../../constants/apiURLs";

const AddTool = () => {
  const history = useHistory(); // Instancia de history para redirigir
  const [open, setOpen] = useState(false); // Estado para manejar el diálogo

  // Configuración de la mutación con react-query
  const { mutate, isLoading } = useMutation(addToolAPI, {
    onSuccess: () => {
      setOpen(true); // Abre el diálogo al añadir exitosamente
    },
    onError: (error) => {
      if (error.response && error.response.status === 422) {
        alert("Error al añadir herramienta: Datos inválidos.");
      } else if (error.response && error.response.status === 401) {
        alert("No autorizado: Por favor, inicia sesión.");
      } else {
        alert(`Error al añadir herramienta: ${error.message}`);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      contact: "",
      link: "",
      training_link: "",
      desc: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Requerido"),
      contact: Yup.string().required("Requerido"),
      link: Yup.string().url("Debe ser un link válido").required("Requerido"),
      training_link: Yup.string().url("Debe ser un link válido"),
      desc: Yup.string().required("Requerido"),
    }),
    onSubmit: (values) => {
      mutate(values); // Llama a la mutación
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Paper style={{ padding: 30 }}>
          <Typography variant="h5" gutterBottom>
            Añadir Herramienta
          </Typography>
          <TextField
            id="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
            margin="normal"
          />
          <TextField
            id="contact"
            label="Contacto"
            value={formik.values.contact}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.contact && Boolean(formik.errors.contact)}
            helperText={formik.touched.contact && formik.errors.contact}
            fullWidth
            margin="normal"
          />
          <TextField
            id="link"
            label="Link"
            value={formik.values.link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.link && Boolean(formik.errors.link)}
            helperText={formik.touched.link && formik.errors.link}
            fullWidth
            margin="normal"
          />
          <TextField
            id="training_link"
            label="Link Capacitación"
            value={formik.values.training_link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.training_link && Boolean(formik.errors.training_link)}
            helperText={formik.touched.training_link && formik.errors.training_link}
            fullWidth
            margin="normal"
          />
          <TextField
            id="desc"
            label="Descripción"
            value={formik.values.desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.desc && Boolean(formik.errors.desc)}
            helperText={formik.touched.desc && formik.errors.desc}
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            disabled={isLoading}
          >
            {isLoading ? "Guardando..." : "Guardar"}
          </Button>
        </Paper>
      </form>

      {/* Diálogo de confirmación */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <Typography>
            La herramienta ha sido añadida exitosamente.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false); // Cierra el diálogo
              history.push("/modulo_km/tool_list/"); // Redirige al listado
            }}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTool;
